import request from '@/utils/request'

// 获取客户统计财务列表
export function findAgentStatisticsFinance(params) {
  return request({
    url: `/agent_statistics/finance`,
    method: 'get',
    params
  })
}

// 导出客户统计财务列表
export function exportAgentStatisticsFinance(data) {
  return request({
    url: `/agent_statistics/finance/export`,
    method: 'post',
    data
  })
}

// 获取客户统计订单列表
export function findAgentStatisticsOrder(params) {
  return request({
    url: `/agent_statistics/order`,
    method: 'get',
    params
  })
}

// 导出客户统计订单列表
export function exportAgentStatisticsOrder(data) {
  return request({
    url: `/agent_statistics/order/export`,
    method: 'post',
    data
  })
}

// 获取客户统计卡用量列表
export function findAgentStatisticsCard(params) {
  return request({
    url: `/agent_statistics/card`,
    method: 'get',
    params
  })
}

// 导出客户统计卡用量列表
export function exportAgentStatisticsCard(data) {
  return request({
    url: `/agent_statistics/card/export`,
    method: 'post',
    data
  })
}

// 获取客户统计列表中客户每月新增数据
export function findAgentStatisticsMonthlyInc(id) {
  return request({
    url: `/agent_statistics/${id}/monthly_inc`,
    method: 'get'
  })
}
