<template>
  <div>
    <search-agent-statistics
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData" style="display: none;">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <a-tooltip color="#FFFFFF">
          <template slot="title">
            <card-monthly-inc-chart
              style="height: 200px; width: 1000px;"
              :id="record.id"
            />
          </template>
          <div v-if="isShowMenu('agents/show')">
            <router-link :to="'/agents/' + record.agent_id + '/info'"> {{ name }} </router-link>
          </div>
          <div v-else>{{ name }}</div>
        </a-tooltip>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchAgentStatistics from '@/views/agent_statistics/Search'
import CardMonthlyIncChart from '@/views/agent_statistics/CardMonthlyIncChart'
import Pagination from '@/components/Pagination'
import { hasPermission } from '@/utils/permission'
import { findAgentStatisticsCard, exportAgentStatisticsCard } from '@/api/agent_statistics'
import { exportExcel } from '@/api/excel'
export default {
  name: 'AgentStatisticsCard',
  components: {
    SearchAgentStatistics,
    CardMonthlyIncChart,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_card: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'name',
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '截止月份',
          dataIndex: 'month'
        },
        {
          title: '累计采卡量(张)',
          dataIndex: 'purchased_card',
          sorter: true,
          sortCard: this.sort.sort_field === 'purchased_card' ? this.sort.sort_card : false
        },
        {
          title: '近1月采卡量(张)',
          dataIndex: 'purchased_card_inc1',
          sorter: true,
          sortCard: this.sort.sort_field === 'purchased_card_inc1' ? this.sort.sort_card : false
        },
        {
          title: '近3月采卡量(张)',
          dataIndex: 'purchased_card_inc3',
          sorter: true,
          sortCard: this.sort.sort_field === 'purchased_card_inc3' ? this.sort.sort_card : false
        },
        {
          title: '近6月采卡量(张)',
          dataIndex: 'purchased_card_inc6',
          sorter: true,
          sortCard: this.sort.sort_field === 'purchased_card_inc6' ? this.sort.sort_card : false
        },
        {
          title: '近12月采卡量(张)',
          dataIndex: 'purchased_card_inc12',
          sorter: true,
          sortCard: this.sort.sort_field === 'purchased_card_inc12' ? this.sort.sort_card : false
        },
        {
          title: '责任销售',
          dataIndex: 'sales_full_names'
        }
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_card = sorter.card
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findAgentStatisticsCard(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportAgentStatisticsCard(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style>
.ant-tooltip {
  max-width: 1010px;
}
</style>
