<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :xxl="6">
          <a-form-item label="客户名称">
            <a-input
              v-decorator="['agent_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" v-if="isShowSaleOption">
          <a-form-item label="责任销售">
            <a-select
              :loading="loadingSaleOptions"
              @focus="fetchSalesOptions"
              v-decorator="['sale_id']"
              allow-clear
            >
              <a-select-option
                v-for="user in salesOptions"
                :key="user.id"
                :value="user.id"
              >
                {{ user.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="10" :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="截止月份">
            <a-month-picker
              style="width: 100%;"
              :disabled-date="disabledDate"
              v-decorator="['end_month']"
              placeholder="请选择月份"
            />
          </a-form-item>
        </a-col>

        <a-col :span="10" :xxl="8">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findEffectiveSaleOptions } from '@/api/user'
import { formatYearMonth } from '@/utils/time'
export default {
  name: 'SearchAgentStatisticsData',
  props: {
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false,
      salesOptions: [],
      loadingSaleOptions: false,
      form: this.$form.createForm(this, { name: 'agent_statistics_search' })
    }
  },
  computed: {
    isShowSaleOption() {
      return this.$store.getters.userRole !== 'sale' && this.$store.getters.userRole !== 'sale_assistant'
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    fetchSalesOptions() {
      if (this.salesOptions.length === 0) {
        findEffectiveSaleOptions().then((res) => {
          if (res.code === 0) {
            this.salesOptions = res.data
          }
          this.loadingSaleOptions = false
        })
      }
    },

    disabledDate(current) {
      // 只能选择当月以前的月份，不含当月
      return current && current.endOf('month') > this.$moment().endOf('month')
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        end_month: formatYearMonth(fieldsValue.end_month)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      // 清空选项
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
