<template>
  <div class="box">
    <a-spin :spinning="loading">
      <div
        ref="cardMonthlyIncChart"
        id="cardMonthlyIncChart"
        style="height:200px; width:1000px;"
      />
    </a-spin>
  </div>
</template>

<script>
import { findAgentStatisticsMonthlyInc } from '@/api/agent_statistics'

export default {
  name: 'CardMonthlyIncChart',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    // 节流
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      loading: true,
      statistics: [], // 图表数据
      chart: null
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    // 指定图表的配置项和数据
    chartOption() {
      const colors = [this.$primaryColor]
      return {
        color: colors,
        title: {
          text: '最近一年客户每月在用卡量趋势',
          left: 'center',
          bottom: '0',
          textStyle: {
            color: this.$primaryColor,
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 'normal'
          }
        },
        grid: {
          left: 0,
          right: 50,
          bottom: 35,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            name: '月份',
            axisTick: {
              alignWithLabel: true
            },
            nameTextStyle: {
              color: this.$primaryColor
            },
            axisLabel: {
              color: '#FFFFFF'
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '数量(个)',
            min: 0,
            minInterval: 1,
            nameTextStyle: {
              color: this.$primaryColor
            },
            axisLabel: {
              color: '#FFFFFF'
            }
          }
        ],
        series: [
          {
            name: '月在用卡量(个)',
            type: 'line',
            itemStyle: {
              color: this.$primaryColor
            },
            label: {
              show: true,
              color: '#FFFFFF'
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    // 根据数据绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.cardMonthlyIncChart)
      // 横坐标月份
      this.chartOption.xAxis[0].data = this.$lodash.map(this.statistics, 'month')
      // 数量
      this.chartOption.series[0].data = this.$lodash.map(this.statistics, 'actived_card')
      // 使用刚指定的配置项和数据显示图表
      this.chart.setOption(this.chartOption)
    },

    fetchData() {
      this.loading = true
      findAgentStatisticsMonthlyInc(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.statistics = res.data
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
}
</style>
